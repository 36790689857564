<script>
import LegoModalLayout from '@/views/Helexium/Lego/LegoModalLayout.vue'

export default {
  components: {
    LegoModalLayout
  },

}


</script>



<template>
  <LegoModalLayout>
    <template #header="{ close }">
      <header class="header">
        <ModalHeaderControls />
        <AddBtn class="close" @click="close" />
      </header>
    </template>
  </LegoModalLayout>
</template>